import { useState, useEffect } from 'react'

import { Link } from '@remix-run/react'

import { IconArrowRight } from '~/components/ui/icons/arrow-right'
import { NO_IMAGE_URL } from '~/constants'
import { LinkToMap, SearchResultCount } from '~/features/common'
import { SelectSearchResultOrder } from '~/features/common/select-search-result-order/select-search-result-order'
import {
  SearchResultOrder,
  SearchResultOrderItem,
} from '~/features/common/select-search-result-order/use-select-search-result-order'
import { NoResultNotice } from '~/features/search-result-page/components/no-result-notice'
import { Office } from '~/models/types'

const formatDate = (date: Date | string): string => {
  try {
    const dateObj = date instanceof Date ? date : new Date(date)
    if (isNaN(dateObj.getTime())) {
      console.error('Invalid date:', date)
      return '日付情報なし'
    }
    return dateObj.toLocaleDateString('ja-JP', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  } catch (error) {
    console.error('Error formatting date:', error)
    return '日付情報なし'
  }
}

type Props = {
  newOffices: Office[]
  newOfficesCount?: number
  mapLink?: string
  prefectureSlug?: string
}

export const NewOffices = ({
  newOffices,
  newOfficesCount,
  mapLink,
  prefectureSlug,
}: Props) => {
  const [sortOrder, setSortOrder] =
    useState<SearchResultOrder>('registerDateAsc')
  const [isSortOpen, setIsSortOpen] = useState(false)

  useEffect(() => {
    if (isSortOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isSortOpen])

  const sortItems: SearchResultOrderItem[] = [
    { label: '新着順', value: 'registerDateAsc' },
    { label: '更新日順', value: 'updateDateDesc' },
  ]

  const sortedOffices = [...newOffices].sort((a, b) => {
    const aCreated = a.createdAt ? new Date(a.createdAt).getTime() : 0
    const bCreated = b.createdAt ? new Date(b.createdAt).getTime() : 0
    const aUpdated = a.updatedAt ? new Date(a.updatedAt).getTime() : 0
    const bUpdated = b.updatedAt ? new Date(b.updatedAt).getTime() : 0

    if (sortOrder === 'registerDateAsc') {
      return bCreated - aCreated
    } else {
      return bUpdated - aUpdated
    }
  })

  const displayOffices = sortedOffices?.slice(0, 6) || []

  return (
    <div className="mx-auto mb-4 w-[100%] max-w-[359px] rounded-lg md:max-w-[808px]">
      <div className="flex flex-col pb-6 pt-6 md:py-0">
        {newOfficesCount !== undefined ? (
          <div className="mb-4 rounded-md px-0 py-2">
            <div className="flex items-center">
              <img
                alt="Office icon"
                className="h-8 w-8"
                src="/icons/ic-flag.svg"
              />
              <h5 className="ml-2 text-xl font-bold">新着オフィス</h5>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <div className="flex items-center gap-2 pl-1">
                <SearchResultCount
                  count={newOfficesCount}
                  preCountText="検索結果"
                />
                <SelectSearchResultOrder
                  items={sortItems}
                  onSelect={(value) => {
                    setSortOrder(value)
                    setIsSortOpen(false)
                  }}
                  open={isSortOpen}
                  placeholder={
                    sortOrder === 'registerDateAsc' ? '新着順' : '更新日順'
                  }
                  setOpen={setIsSortOpen}
                  size="small"
                />
              </div>
              <div className="hidden md:block">
                <LinkToMap
                  className="z-10"
                  href={mapLink}
                  variant="resultButton"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="mb-4 flex items-center justify-between rounded-md px-0 py-2">
            <div className="flex items-center">
              <img
                alt="Office icon"
                className="h-8 w-8"
                src="/icons/ic-flag.svg"
              />
              <h5 className="ml-2 text-xl font-bold">新着オフィス</h5>
            </div>
            <SelectSearchResultOrder
              items={sortItems}
              onSelect={(value) => {
                setSortOrder(value)
                setIsSortOpen(false)
              }}
              open={isSortOpen}
              placeholder={
                sortOrder === 'registerDateAsc' ? '新着順' : '更新日順'
              }
              setOpen={setIsSortOpen}
              size="small"
            />
          </div>
        )}
        <div className="mx-auto grid w-full max-w-[343px] grid-cols-1 gap-6 md:w-full md:max-w-none md:grid-cols-2 md:gap-4">
          {displayOffices.length > 0 ? (
            displayOffices.map((office) => (
              <Link
                className="mx-auto block h-[426px] w-full max-w-[343px] overflow-hidden rounded-lg border border-[#DADADA] bg-white shadow-sm transition-shadow hover:shadow-md md:mx-0 md:h-[414px] md:w-full md:max-w-none"
                key={office.id}
                to={`/prefectures/${office.areas[0]?.prefectures[0]?.slug || 'tokyo'}/offices/${office.id}`}
              >
                <div className="relative h-[256px]">
                  <img
                    alt={office.name}
                    className="h-full w-full object-cover"
                    src={
                      office.images?.find((img) => img.isEyeCatch)?.url ||
                      office.images?.[0]?.url ||
                      NO_IMAGE_URL
                    }
                  />
                </div>
                <div className="p-4">
                  <p className="mb-0 text-sm font-medium text-[#09909E]">
                    {formatDate(
                      sortOrder === 'registerDateAsc'
                        ? office.createdAt
                        : office.updatedAt,
                    )}
                  </p>
                  <h4 className="mb-4 line-clamp-1 text-base font-semibold">
                    {office.name}
                  </h4>

                  <div className="mb-2 flex items-start gap-2">
                    <div className="flex-shrink-0">
                      <img
                        alt="Address icon"
                        className="mt-0.5 h-[24px] w-[24px]"
                        src="/images/address.svg"
                      />
                    </div>
                    <p className="line-clamp-1 text-base text-[#637381]">
                      {office.address}
                    </p>
                  </div>

                  <div className="mb-4 flex items-start gap-2">
                    <div className="flex-shrink-0">
                      <img
                        alt="Train icon"
                        className="mt-0.5 h-[24px] w-[24px]"
                        src="/images/train.svg"
                      />
                    </div>
                    <p className="line-clamp-1 text-base text-[#637381]">
                      {office.stations && office.stations.length > 0
                        ? office.stations
                            .slice(0, 2)
                            .map(
                              (station, index) =>
                                `${station.name} 徒歩${station.time}分${index < Math.min(office.stations.length - 1, 1) ? '、' : ''}`,
                            )
                            .join('')
                        : '交通手段情報なし'}
                    </p>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <div className="col-span-full flex w-full items-center justify-center">
              <div className="w-full py-2 text-center">
                <NoResultNotice onClick={() => {}} />
              </div>
            </div>
          )}
        </div>
        {displayOffices.length > 0 && (
          <div className="mt-8 flex justify-center">
            <Link
              className="flex w-[254px] items-center justify-center gap-x-1 rounded-md bg-[#09909E] px-6 py-3 text-center text-base font-semibold text-white transition-colors hover:bg-[#09909E]/90"
              to={
                prefectureSlug
                  ? `/prefectures/${prefectureSlug}/search/`
                  : `/new-offices/search/`
              }
            >
              もっとオフィスを見る
              <IconArrowRight
                className="mt-0.5"
                color="white"
                height="24"
                strokeWidth={1.2}
                width="24"
              />
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
