import { LinkToMap } from '~/features/common'

type Props = {
  mapLink?: string
}

export const SearchByMap = ({ mapLink }: Props) => {
  return (
    <section className="relative">
      <div className="absolute inset-0 z-10 h-[500px] w-full overflow-hidden">
        <img
          alt="Map background"
          className="h-full w-full object-cover"
          src="/images/map3.png"
          style={{
            objectPosition: 'center 30%',
            transform: 'scale(1.1)',
            transformOrigin: 'center center',
          }}
        />
        <div
          className="absolute inset-0"
          style={{
            backgroundColor: '#09909E',
            opacity: '0.24',
          }}
        />
      </div>

      <div className="relative z-20 -mt-8 md:-mt-12">
        <picture className="mb-[-320px] mt-20 flex h-[220px] justify-center md:mb-[-200px] md:mt-40 md:h-[120px]">
          <source media="(max-width: 767px)" srcSet="/images/frame1-sp.png" />
          <source media="(min-width: 766px)" srcSet="/images/frame1-pc.png" />
          <img
            alt="Responsive Frame"
            className="h-auto w-auto"
            src="/images/frame1-pc.png"
          />
        </picture>
        <LinkToMap
          className="relative z-20 mt-20 h-[600px] md:h-[400px]"
          href={mapLink}
        />
        <picture className="mt-[-235px] flex h-[50px] justify-center pl-2 md:mt-[-160px] md:h-[60px] md:justify-center">
          <source media="(max-width: 769px)" srcSet="/images/frame2-sp.png" />
          <source media="(min-width: 768px)" srcSet="/images/frame2-pc.png" />
          <img
            alt="Responsive Frame"
            className="h-auto w-auto"
            src="/images/frame1-pc.png"
          />
        </picture>
      </div>
    </section>
  )
}
